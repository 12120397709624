import { TFunction } from "i18next";
import { DataSourceTypes } from "~/modules/datalang/dtos/DataSourceType";
import { HeaderBlockDto } from "~/modules/pageBlocks/components/blocks/marketing/header/HeaderBlockUtils";

export function defaultHeader({ t }: { t: TFunction }): HeaderBlockDto {
  return {
    style: "simple",
    withLogo: true,
    withSignInAndSignUp: true,
    withLanguageSelector: true,
    withDarkModeToggle: false,
    withThemeSelector: false,
    links: [
      { path: "/pricing", title: "front.navbar.pricing" },
      // { path: "/demo", title: "Demo" },
      { path: "/docs", title: "Docs", className: "" },
      {
        title: "Demo",
        items: [
          {
            path: "/chat-with",
            title: t("shared.all"),
            titleMobile: t("shared.demo"),
          },
          ...DataSourceTypes.filter((f) => f.demoSlug).map((f) => ({
            path: `/chat-with/${f.demoSlug}`,
            title: t(`datalang.dataSourceTypes.${f.value}`),
            // hint: f.underConstruction ? t("shared.underConstruction") : undefined,
            dataSourceType: f.value,
            desktopOnly: true,
          })),
        ],
      },
      {
        title: "shared.more",
        items: [
          { path: "/blog", title: "Blog" },
          { path: "https://www.youtube.com/@datalangai", title: "YouTube Channel", target: "_blank" },
          // {
          //   title: t("front.hero.tour"),
          //   tour: {
          //     type: "storylane",
          //     url: "https://app.storylane.io/demo/q5hpae4vmpo7",
          //   },
          // },
          // { path: "/docs", title: "Docs" },
          { path: "/newsletter", title: t("front.navbar.newsletter") },
          { path: "/contact", title: t("front.navbar.contact") },
          { path: "/changelog", title: t("front.changelog.title") },
          { path: "/affiliate-program", title: t("front.navbar.affiliates") },
          { path: "https://saasrock.com/?ref=datalang", title: "by SaasRock", target: "_blank" },
        ],
      },
    ],
  };
}
